<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="from_date">From</label>
                <SingleDatePicker
                  id="from_date"
                  v-model="reportData.fromDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="from date"
                  data-cy="leave-from-date"
                  name="from_date"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                  readonly
                  emits-moment
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="to_date">To</label>
                <SingleDatePicker
                  id="to_date"
                  v-model="reportData.toDate"
                  :format="dateFormatWithSlashByLocale"
                  :placeholder="dateFormatWithSlashByLocale"
                  data-vv-as="to date"
                  data-cy="leave-to-date"
                  name="to_date"
                  class="form-control"
                  autocomplete="off"
                  type="text"
                  readonly
                  emits-moment
                />
              </div>
              <div class="form-group">
                <label class="form-label" for="leave_type">Type</label>
                <div class="tw-relative tw-w-full">
                  <RequestTypePicker
                    id="leave_type"
                    v-model="reportData.selectedLeaveType"
                    :options="selectableLeaveTypes"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="leave_status">Status</label>
                <div class="tw-relative tw-w-full">
                  <LeaveStatusPicker
                    id="leave_status"
                    v-model="reportData.selectedLeaveStatuses"
                    :placeholder="'All'"
                  />
                </div>
              </div>
            </div>
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportData.selectedDepartment"
                    :options="selectableDepartments"
                    @input="getEmployments"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="employment">Employee</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="employment"
                    v-model="reportData.selectedEmployment"
                    :options="selectableEmployments"
                  />
                </div>
              </div>
              <div class="form-group tw-mb-0">
                <label class="form-label" for="report-type">Report Type</label>
                <div class="tw-relative tw-w-full">
                  <ReportTypePicker
                    id="report-type"
                    v-model="reportData.reportType"
                    :options="selectableReportTypes"
                    :reduce="option => option.id"
                  />
                </div>
              </div>
              <div class="tw-flex tw-justify-start">
                <SpinnerButton
                  :disabled="downloadingLeave"
                  :loading="downloadingLeave"
                  :spinner-only="true"
                  class="tw-mt-6"
                  data-cy="download-xl"
                  @click="downloadLeaves"
                >
                  Download
                </SpinnerButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <div v-show="reportData.reportType === 'detail-report'">
            <LeaveDetailReportTable
              :leave-reports="paginatedLeaves.data"
              :loading="fetchingLeave"
            />
          </div>
          <div v-show="reportData.reportType === 'summary-report'">
            <LeaveSummaryReportTable
              :leave-reports="paginatedLeaves.data"
              :loading="fetchingLeave"
            />
          </div>
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedLeaves.current_page"
            :page-count="pageCount"
            :click-handler="fetchLeaves"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import RequestTypePicker from '@/components/pickers/RequestTypePicker'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import ReportTypePicker from '@/components/pickers/ReportTypePicker'
import LeaveStatusPicker from '@/components/pickers/LeaveStatusPicker'
import LeaveDetailReportTable from '@/components/reports/leave/LeaveDetailReportTable'
import LeaveSummaryReportTable from '@/components/reports/leave/LeaveSummaryReportTable'
import SpinnerButton from '@/components/SpinnerButton'
import { maxBy, minBy } from 'lodash-es'

const SingleDatePicker = () => import('@/components/SingleDatePicker')

const PAGINATED_LEAVE = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
}

export default {
  name: 'LeaveReport',

  components: {
    Pagination,
    SingleDatePicker,
    ReportTypePicker,
    RequestTypePicker,
    LeaveStatusPicker,
    DepartmentPicker,
    EmploymentPicker,
    LeaveDetailReportTable,
    LeaveSummaryReportTable,
    SpinnerButton,
  },

  mixins: [FormatDate, HandleReportData, ValidatesForm],

  data() {
    return {
      leaveTypes: [],
      departments: [],
      employments: [],
      reportData: {
        reportType: 'detail-report',
        selectedLeaveType: '',
        selectedDepartment: '',
        selectedEmployment: '',
        selectedLeaveStatuses: [],
        toDate: moment(),
        fromDate: moment().startOf('month'),
      },
      fetchingLeave: false,
      downloadingLeave: false,
      paginatedLeaves: PAGINATED_LEAVE,
    }
  },

  computed: {
    pageCount() {
      return Math.ceil(
        this.paginatedLeaves.total / this.paginatedLeaves.per_page
      )
    },

    dateFormatWithSlashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    selectableLeaveTypes() {
      return [this.allOption, ...this.leaveTypes]
    },

    selectableEmployments() {
      return [this.allOption, ...this.employments]
    },

    selectableReportTypes() {
      return [
        {
          id: 'detail-report',
          name: 'Detail',
        },
        {
          id: 'summary-report',
          name: 'Summary',
        },
      ]
    },

    fromDate() {
      const dates = [this.reportData.fromDate, this.reportData.toDate]

      return minBy(dates, date => date.unix()).startOf('day')
    },

    toDate() {
      const dates = [this.reportData.fromDate, this.reportData.toDate]

      return maxBy(dates, date => date.unix()).endOf('day')
    },

    within() {
      return `${this.fromDate.format()},${this.toDate.format()}`
    },
  },

  watch: {
    'reportData.reportType': {
      handler() {
        if (
          this.reportData.reportType === 'summary-report' &&
          !this.reportData.selectedLeaveStatuses.length
        ) {
          this.reportData.selectedLeaveStatuses = [
            {
              name: 'Requested',
              value: 'requested',
            },
            {
              name: 'Approved',
              value: 'approved',
            },
          ]
        }
      },
    },

    reportData: {
      deep: true,
      handler() {
        this.paginatedLeaves = PAGINATED_LEAVE
        this.fetchLeaves()
      },
    },

    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.reportData = {
          reportType: 'detail-report',
          selectedLeaveType: this.allOption,
          selectedDepartment: this.allOption,
          selectedEmployment: this.allOption,
          selectedLeaveStatuses: [],
          toDate: moment(),
          fromDate: moment().startOf('month'),
        }

        this.fetchLeaveTypes()
        this.fetchDepartments()
        this.getEmployments()
      },
    },
  },

  methods: {
    async fetchLeaveTypes() {
      try {
        const { data } = await this.$http.get('leave-types', {
          params: {
            company_id: this.activeCompany.id,
          },
        })

        this.leaveTypes = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    getEmployments() {
      this.reportData.selectedEmployment = this.allOption

      if (this.isAdmin) this.fetchEmployments()
      if (this.isNonAdminApprover) this.getSubordinates()
    },

    async fetchEmployments() {
      this.loading = true

      try {
        const { data } = await this.$http.get('employments', {
          params: {
            company_id: this.activeCompany.id,
            department: this.reportData.selectedDepartment.id,
          },
        })

        this.employments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchLeaves(page) {
      this.fetchingLeave = true

      try {
        const { data } = await this.$http.get('reports/leave', {
          params: {
            page: page || this.paginatedLeaves.current_page,
            company_id: this.activeCompany.id,
            type: this.reportData.selectedLeaveType.id,
            status: this.joinToString(this.reportData.selectedLeaveStatuses),
            owner: this.reportData.selectedEmployment.id,
            department: this.reportData.selectedDepartment.id,
            within: this.within,
            report_type: this.reportData.reportType,
          },
        })

        this.paginatedLeaves = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.fetchingLeave = false
    },

    async downloadLeaves() {
      this.downloadingLeave = true

      try {
        const { data } = await this.$http.get('reports/downloads/leave', {
          params: {
            company_id: this.activeCompany.id,
            type: this.reportData.selectedLeaveType.id,
            status: this.joinToString(this.reportData.selectedLeaveStatuses),
            owner: this.reportData.selectedEmployment.id,
            department: this.reportData.selectedDepartment.id,
            within: this.within,
            report_type: this.reportData.reportType,
          },
          responseType: 'blob',
        })

        const format = 'YYYY-MM-DD'

        FileSaver.saveAs(
          new Blob([data]),
          `leave-${this.reportData.reportType}-${this.fromDate.format(
            format
          )}-${this.toDate.format(format)}.xlsx`
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.downloadingLeave = false
    },

    dateRules() {
      if (this.authUser.locale === 'en_US') {
        return 'date_format:MM/dd/yyyy'
      }

      return 'date_format:dd/MM/yyyy'
    },

    joinToString(items) {
      return items.length ? items.map(item => item.value).join(',') : null
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
