export default class PartnerStack {
  static signUp(customer) {
    try {
      if (!window.growsumo.data.xid) return

      window.growsumo.data.name = customer.name
      window.growsumo.data.email = customer.email
      window.growsumo.data.customer_key = customer.id

      window.growsumo.createSignup()
    } catch (e) {
      //
    }
  }
}
