<template>
  <tr>
    <td v-if="allowanceTypeRowspan" :rowspan="allowanceTypeRowspan">
      {{ allowance.allowanceType().name }}
    </td>
    <td v-if="allowanceUnitRowspan" :rowspan="allowanceUnitRowspan">
      {{ allowance.allowanceUnit() }}
    </td>
    <td>
      {{ allowanceYear }}
    </td>
    <td>
      {{ totalAnnualAllowance }}
    </td>
    <td v-if="hasToilOfferedForAtLeastOneAllowance">
      <span v-if="allowance.hasTimeOffInLieu()">
        {{ allowance.totalTimeOffInLieu() }}
      </span>
      <span v-else>-</span>
    </td>
    <td>
      {{ totalCarryoverAllowance }}
    </td>
    <td>
      {{ totalExpireCarryoverAllowance }}
    </td>
    <td>{{ allowance.totalAllowance() }}</td>
    <td>{{ allowance.accruedAllowance() }}</td>
    <td>{{ allowance.bookedAllowance() }}</td>
    <td>{{ allowance.takenAllowance() }}</td>
    <td>{{ allowance.remainingToBook() }}</td>
    <td>{{ allowance.remainingToTake() }}</td>
    <td>
      {{ totalCarriedToNextYearAllowance }}
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'

export default {
  name: 'AllowanceSummaryReportTableRow',

  mixins: [FormatDate],

  props: {
    allowance: {
      type: EmploymentAllowance,
      required: true,
    },

    allowanceTypeRowspan: {
      type: Number,
      required: true,
    },

    allowanceUnitRowspan: {
      type: Number,
      required: true,
    },

    hasToilOfferedForAtLeastOneAllowance: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    allowanceYear() {
      return (
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          moment.utc(this.allowance.calendar().start_date),
          'utc'
        ) +
        ' - ' +
        this.formatDateFromIsoToDayReadableShortDayNumberShortMonthYear(
          moment.utc(this.allowance.calendar().end_date),
          'utc'
        )
      )
    },

    totalAnnualAllowance() {
      if (this.allowance.annualAllowance() === 0) return '-'

      return this.allowance.annualAllowance()
    },

    totalCarryoverAllowance() {
      if (this.allowance.carryoverAllowance() === 0) return '-'

      return this.allowance.carryoverAllowance()
    },

    totalExpireCarryoverAllowance() {
      return this.allowance.expiredCarryoverAllowance() == null
        ? '-'
        : this.allowance.expiredCarryoverAllowance()
    },

    totalCarriedToNextYearAllowance() {
      if (this.allowance.carriedAllowanceToNextYear() === 0) return '-'

      return this.allowance.carriedAllowanceToNextYear()
    },
  },
}
</script>
