import http from '@/plugins/http'

export default class Profile {
  static async sendPasswordResetLink(username, recaptchaToken) {
    await http.get('sanctum/csrf-cookie')

    return http.post('password/email', {
      email: username,
      'g-recaptcha-response': recaptchaToken,
    })
  }

  static async resetPassword(payload) {
    await http.get('sanctum/csrf-cookie')

    return http.post('/password/reset', payload)
  }

  static async verifyAccount(token) {
    await http.get('sanctum/csrf-cookie')

    return http.post('verify-account', {
      verification_code: token,
    })
  }

  static async verifyInvitation(email, token) {
    await http.get('sanctum/csrf-cookie')

    return http.post('verify-invitation', {
      email: email,
      invitation_code: token,
    })
  }

  static async deleteProfile() {
    return http.delete('me')
  }

  static async verifyPendingEmail(url) {
    return await http.get(url)
  }

  static async resendVerification() {
    return await http.get('pending-email/resend-verification')
  }

  static async uploadAvatar(blob) {
    return http.put('me/avatars', blob, {
      headers: {
        'content-type': 'image/jpeg',
      },
    })
  }

  static async deleteAvatar() {
    return http.delete('me/avatars')
  }

  static async update(form) {
    return http.put('me', form)
  }

  static async updateSettings(settings) {
    return http.put('me/settings', settings)
  }

  static async updateLocale(locale) {
    return http.put('me/locale', {
      locale,
    })
  }

  static async enableEmailNotification() {
    return http.post('me/email-notifications')
  }

  static async disableEmailNotification() {
    return http.delete('me/email-notifications')
  }

  static async enableSlackNotifications() {
    return http.post('me/slack-notifications')
  }

  static async disableSlackNotifications() {
    return http.delete('me/slack-notifications')
  }
}
