<template>
  <div>
    <div class="tw--mx-2 tw-pt-4 tw-flex sm:tw-flex-row-reverse tw-flex-wrap">
      <div class="tw-px-2 tw-w-full md:tw-w-1/3 xl:tw-w-1/5">
        <div class="card">
          <div class="tw-mb-8 tw-text-2xl tw-font-semibold">
            Filters
          </div>
          <div class="sm:tw-flex md:tw-block sm:tw--mx-2 md:tw-mx-0">
            <div
              class="tw-w-full sm:tw-w-1/2 md:tw-w-full sm:tw-px-2 md:tw-px-0"
            >
              <div class="form-group">
                <label class="form-label" for="department">Department</label>
                <div class="tw-relative tw-w-full">
                  <DepartmentPicker
                    id="department"
                    v-model="reportFilters.selectedDepartment"
                    :options="selectableDepartments"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="employment">Employee</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="employment"
                    v-model="reportFilters.selectedEmployment"
                    :options="selectableEmployments"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="approver">Approver</label>
                <div class="tw-relative tw-w-full">
                  <EmploymentPicker
                    id="approver"
                    v-model="reportFilters.selectedApprover"
                    cypress-attribute="approver"
                    :options="selectableApprovers"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="source">Source</label>
                <div class="tw-relative tw-w-full">
                  <ApproverSourcePicker
                    id="source"
                    v-model="reportFilters.source"
                    :options="selectableSource"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-justify-start">
            <SpinnerButton
              :disabled="isDownloadingApproverReport"
              :loading="isDownloadingApproverReport"
              :spinner-only="true"
              class="tw-mt-6"
              data-cy="download-xl"
              @click="downloadApproverReport"
            >
              Download
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div class="tw-px-2 tw-w-full md:tw-w-2/3 xl:tw-w-4/5">
        <div class="card">
          <ApproverReportTable
            :loading="isFetchingApproverReport"
            :approvers="paginatedApprovers.data"
          />
        </div>

        <div class="paginate-wrapper">
          <Pagination
            :current-page="paginatedApprovers.current_page"
            :page-count="pageCount"
            :click-handler="fetchApproverReport"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleReportData from '@/mixins/HandleReportData'
import Pagination from '@/components/pagination/Pagination'
import DepartmentPicker from '@/components/pickers/DepartmentPicker'
import SpinnerButton from '@/components/SpinnerButton'
import ApproverReportTable from '@/components/reports/approver/ApproverReportTable'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import { Employments } from '@/api'
import ApproverSourcePicker from '@/components/pickers/ApproverSourcePicker'
import Approver from '@/api/reporting/Approver'

const PAGINATED_APPROVERS = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
}

export default {
  name: 'ApproverReport',

  components: {
    EmploymentPicker,
    ApproverReportTable,
    Pagination,
    DepartmentPicker,
    SpinnerButton,
    ApproverSourcePicker,
  },

  mixins: [HandleReportData, ValidatesForm],

  data() {
    return {
      isFetchingApproverReport: false,
      isDownloadingApproverReport: false,
      reportFilters: {
        selectedDepartment: '',
        selectedEmployment: '',
        selectedApprover: '',
        source: '',
      },
      departments: [],
      employments: [],
      approvers: [],
      paginatedApprovers: PAGINATED_APPROVERS,
    }
  },

  computed: {
    pageCount() {
      return Math.ceil(
        this.paginatedApprovers.total / this.paginatedApprovers.per_page
      )
    },

    selectableEmployments() {
      return [this.allOption, ...this.employments]
    },

    selectableApprovers() {
      return [this.allOption, ...this.approvers]
    },

    selectableSource() {
      const departmentAndEmploymentOptions = [
        { id: 'department', name: 'Department' },
        { id: 'employment', name: 'Employee' },
      ]

      if (this.activeEmployment.is_admin) {
        return [
          this.allOption,
          ...[
            { id: 'admin', name: 'Admin' },
            ...departmentAndEmploymentOptions,
          ],
        ]
      }

      return [this.allOption, ...[...departmentAndEmploymentOptions]]
    },
  },

  watch: {
    reportFilters: {
      deep: true,
      handler() {
        this.paginatedApprovers = PAGINATED_APPROVERS
        this.fetchApproverReport()
      },
    },

    '$route.query.company': {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.generateReport()
      },
    },

    'reportFilters.selectedDepartment': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchEmployments()
      },
    },
  },

  methods: {
    async fetchApproverReport(page) {
      this.isFetchingApproverReport = true

      try {
        const { data } = await Approver.getReport({
          page: page || this.paginatedApprovers.current_page,
          company_id: this.activeCompany.id,
          department_id: this.reportFilters.selectedDepartment.id,
          employment_id: this.reportFilters.selectedEmployment.id,
          approver_id: this.reportFilters.selectedApprover.id,
          source: this.reportFilters.source.id,
        })

        this.paginatedApprovers = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.isFetchingApproverReport = false
    },

    async downloadApproverReport() {
      this.isDownloadingApproverReport = true

      try {
        const date = moment().format('YYYY-MM-DD')

        const { data } = await Approver.download({
          date: date,
          company_id: this.activeCompany.id,
          department_id: this.reportFilters.selectedDepartment.id,
          employment_id: this.reportFilters.selectedEmployment.id,
          approver_id: this.reportFilters.selectedApprover.id,
          source: this.reportFilters.source.id,
        })

        FileSaver.saveAs(new Blob([data]), 'approver-report-' + date + '.xlsx')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.isDownloadingApproverReport = false
    },

    async fetchEmployments() {
      try {
        const { data } = await Employments.find({
          company_id: this.activeCompany.id,
          department: this.reportFilters.selectedDepartment.id,
        })

        this.employments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    findApprovers() {
      this.approvers = this.employments.filter(function(employment) {
        return employment.is_approver || employment.is_admin
      })
    },

    async generateReport() {
      this.fetchDepartments()
      await this.fetchEmployments()
      this.findApprovers()

      this.reportFilters = {
        selectedDepartment: this.allOption,
        selectedEmployment: this.allOption,
        selectedApprover: this.$route.params.approver
          ? { id: this.$route.params.approver }
          : this.allOption,
        source: this.allOption,
      }
    },
  },
}
</script>

<style>
.paginate-wrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
