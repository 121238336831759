<template>
  <div class="tw-w-full w-450" data-cy="create-company-form">
    <div class="tw-bg-white tw-shadow-md tw-rounded tw-mb-4">
      <p
        class="tw-font-semibold tw-px-8 tw-pt-10 tw-mb-2 tw-text-2xl tw-text-gray-800"
      >
        {{ greeting }}
      </p>

      <CompanyForm
        submit-button-text="Next"
        :default-company-name="companyName"
        :locale="authUser.locale"
        :loading="loading"
        @submit="createCompany"
      />
    </div>

    <AuthFooter />
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import CompanyForm from '@/components/companies/CompanyForm'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import PartnerStack from '@/api/partnerstack/PartnerStack'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'CreateCompany',

  components: { AuthFooter, CompanyForm },

  mixins: [ValidatesForm, FormatDate],

  props: {
    date: {
      type: moment,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    companyName() {
      return `${this.authUserFirstName}'s Company`
    },

    authUserFirstName() {
      return this.authUser.first_name
    },

    greeting() {
      return `Welcome, ${this.authUserFirstName}!`
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    setLoadingState(loading) {
      this.loading = loading
    },

    async createCompany(form) {
      this.loading = true

      try {
        const { data: company } = await this.$http.post('companies', {
          name: form.company.name,
          annual_leave_allowance: form.company.annualLeaveAllowance,
          has_carry_over: form.hasCarryOver,
          maximum_carry_over_in_days: form.maximumCarryOverInDays,
          expiry_period: form.expiryPeriod,
          expiry_unit: form.expiryUnit.key,
          subscribe_for_newsletter: true,
          start_date: this.date.format('YYYY-MM-DD'),
        })

        await this.fetchUser()

        const customer = {
          id: company.id,
          name: company.name,
          email: this.authUser.email,
        }

        PartnerStack.signUp(customer)

        this.success('Company saved successfully.')

        this.$router.replace(
          {
            query: {
              ...this.$route.query,
              company: company.id,
            },
          },
          () => {
            //
          }
        )

        this.$emit('next', 'InviteUsers')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },
  },
}
</script>
