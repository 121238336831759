<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title">
        <h2 class="page-title">Employee Report</h2>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table class="ld-table ld-table-striped" data-cy="table-employee-report">
        <thead>
          <tr>
            <th>Title</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Date of birth</th>
            <th>Start Date</th>
            <th>Years of Service</th>
            <th>Allowance Unit</th>
            <th>Hours per working day</th>
            <th>Timezone</th>
            <th>Public Holidays</th>
            <th>Admin</th>
            <th>Approver</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="employments.length">
            <EmploymentReportsTableRow
              v-for="employment in employments"
              :key="employment.id"
              :employment="employment"
            />
          </template>
          <tr v-if="!loading && !employments.length">
            <td class="tw-bg-white" colspan="15">
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>
      <loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('@/components/NoData')
const EmploymentReportsTableRow = () =>
  import('@/components/reports/employments/EmploymentReportsTableRow')

export default {
  name: 'EmploymentReportsTable',

  components: {
    NoData,
    Loading,
    EmploymentReportsTableRow,
  },

  props: {
    employments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
