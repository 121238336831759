<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div><h2 class="page-title">Departments</h2></div>
          <div>
            <SpinnerButton
              data-cy="add-department"
              type="button"
              @click="create"
            >
              Add Department
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div class="tw-relative">
        <div v-scroll-hint class="table-responsive settings-table">
          <DepartmentTable
            :departments="departments"
            @edit-department="edit"
            @delete-department="destroy"
          />
        </div>
      </div>

      <Modal
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="480"
        name="department-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="modal-title">
                {{ editing ? 'Edit' : 'New' }} Department
              </p>
            </div>
            <div data-cy="department-close">
              <button
                class="modal-close"
                @click="$modal.hide('department-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <div class="tw-mt-3 tw-p-3">
          <form class="tw-w-full" @submit.prevent="submit">
            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="name">
                  Department Name <span class="required-field">&#42;</span>
                </label>
                <input
                  id="name"
                  v-model="form.name"
                  v-validate="'required|max:50'"
                  v-focus
                  data-vv-name="name"
                  data-cy="department-name"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                />
                <p
                  v-show="errors.has('name')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  data-cy="department-name-error"
                >
                  {{ errors.first('name') }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="approvers">
                  <div class="tw-flex tw-items-center">
                    <span>
                      Approver(s)
                    </span>
                    <ExtraInfo icon="question">
                      <div class="tw-p-4 tw-w-48">
                        Select who should approve requests for this department.
                        This can be overridden for individual employees.
                        <div>
                          <a
                            class="btn-link tw-font-semibold"
                            target="_blank"
                            href="https://help.leavedates.com/hc/en-us/articles/360008866160-How-to-set-up-who-approves-new-leave-requests"
                            >More info</a
                          >
                        </div>
                      </div>
                    </ExtraInfo>
                  </div>
                </label>
                <EmployeePicker
                  v-model="form.selectedApprovers"
                  :options="approvers.data"
                  :loading="isApproversLoading"
                  :has-next-page="hasNextPageForApprovers"
                  :not-found-message="'No approvers available'"
                  data-cy="approvers"
                  @search-change="name => (approverSearchName = name)"
                  @scroll-down="fetchMoreApprovers"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="followers">
                  <div class="tw-flex tw-items-center">
                    <span>
                      Notify
                    </span>
                    <ExtraInfo icon="question">
                      <div class="tw-p-4 tw-w-48">
                        Select who should be notified of new requests for this
                        department. They will not have access to approve
                        requests.
                        <div>
                          <a
                            class="btn-link tw-font-semibold"
                            target="_blank"
                            href="https://help.leavedates.com/hc/en-us/articles/4412373021330"
                            >More info</a
                          >
                        </div>
                      </div>
                    </ExtraInfo>
                  </div>
                </label>
                <EmployeePicker
                  v-model="form.selectedFollowers"
                  :options="followers.data"
                  :loading="isFollowersLoading"
                  :has-next-page="hasNextPageForFollowers"
                  data-cy="notifiers"
                  :not-found-message="'No notifiers available'"
                  @search-change="name => (followerSearchName = name)"
                  @scroll-down="fetchMoreFollowers"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="visible_scopes_picker">
                  <div class="tw-flex tw-items-center">
                    <span>People in this department can see</span>
                    <ExtraInfo icon="question">
                      <div class="tw-p-4 tw-w-48">
                        You can control whether people in this department can
                        see other teams in the Wall Chart. If you do not select
                        anything here then everyone in this department will be
                        able to see all other departments.
                        <div>
                          <a
                            class="btn-link tw-font-semibold"
                            target="_blank"
                            href="https://help.leavedates.com/hc/en-us/articles/360010559980-Control-what-staff-can-see-in-the-wall-chart"
                            >More info</a
                          >
                        </div>
                      </div>
                    </ExtraInfo>
                  </div>
                </label>

                <VisibilityScopesPicker
                  id="visible_scopes_picker"
                  v-model="form.visible_scopes"
                  :departments="departments"
                  :department="form"
                />
              </div>
            </div>

            <div class="tw-flex tw-flex-wrap tw-mb-3">
              <div class="tw-w-full tw-px-3">
                <div class="tw-flex tw-justify-end">
                  <button
                    v-if="!editing"
                    class="btn btn-borderless btn-link"
                    type="button"
                    data-cy="department-name-clear"
                    @click="reset"
                  >
                    Clear
                  </button>

                  <SpinnerButton
                    :disabled="!valid || loading"
                    :loading="loading"
                    :spinner-only="true"
                    type="submit"
                    data-cy="submit"
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce, uniqBy, filter, uniq } from 'lodash-es'
import ValidatesForm from '@/mixins/ValidatesForm'
const ExtraInfo = () => import('@/components/ExtraInfo')
const DepartmentTable = () => import('@/components/departments/DepartmentTable')
import VisibilityScopesPicker from '@/components/departments/VisibilityScopesPicker'
import DepartmentsShortcuts from '@/mixins/page-shortcuts/settings/DepartmentsShortcuts'
import SpinnerButton from '@/components/SpinnerButton'
import { Departments, Employments, Approvers } from '@/api'
import EmployeePicker from '@/components/EmployeePicker'

const PAGINATED_EMPLOYMENTS = {
  data: [],
  total: 0,
  per_page: 15,
  current_page: 1,
}

export default {
  name: 'Department',

  components: {
    EmployeePicker,
    DepartmentTable,
    VisibilityScopesPicker,
    ExtraInfo,
    SpinnerButton,
  },

  mixins: [ValidatesForm, DepartmentsShortcuts],

  data: () => ({
    departments: [],
    loading: false,
    editing: false,
    isFollowersLoading: false,
    isApproversLoading: false,
    followers: { ...PAGINATED_EMPLOYMENTS },
    approvers: { ...PAGINATED_EMPLOYMENTS },
    followerSearchName: '',
    approverSearchName: '',
    form: {
      id: null,
      name: '',
      visible_scopes: ['everyone'],
      selectedApprovers: [],
      selectedFollowers: [],
    },
  }),

  computed: {
    shouldAddSelfScope() {
      const scopes = this.form.visible_scopes

      return scopes && scopes.includes('this')
    },

    visibleScopes() {
      const scopes = uniq(
        filter(this.form.visible_scopes, scope => scope !== 'this')
      )

      return scopes && scopes.length ? scopes : null
    },

    hasNextPageForFollowers() {
      return (
        Math.ceil(this.followers.total / this.followers.per_page) >
        this.followers.current_page
      )
    },

    hasNextPageForApprovers() {
      return (
        Math.ceil(this.approvers.total / this.approvers.per_page) >
        this.approvers.current_page
      )
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchDepartments()
        this.resetFollowers()
        this.fetchFollowers()
        this.resetApprovers()
        this.fetchApprovers()
      },
    },

    '$route.query.department': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchDepartments()
      },
    },

    followerSearchName: {
      handler(value) {
        this.resetFollowers()
        this.fetchFollowers(value)
      },
    },

    approverSearchName: {
      handler(value) {
        this.resetApprovers()
        this.fetchApprovers(value)
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    async fetchDepartments() {
      try {
        const { data } = await Departments.all({
          ...this.$route.query,
          compact: true,
        })

        this.departments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    resetFollowers: function() {
      this.followers = {
        ...PAGINATED_EMPLOYMENTS,
      }
    },

    resetApprovers: function() {
      this.approvers = {
        ...PAGINATED_EMPLOYMENTS,
      }
    },

    fetchFollowers: debounce(async function(name) {
      this.isFollowersLoading = true

      const { data } = await Employments.find({
        search: name,
        company: this.activeCompany.id,
        page: this.followers.current_page,
      })

      this.followers = {
        ...data,
        data: uniqBy([...this.followers.data, ...data.data], 'id'),
      }

      this.isFollowersLoading = false
    }, 300),

    fetchApprovers: debounce(async function(name) {
      this.isApproversLoading = true
      const { data } = await Approvers.find({
        search: name,
        company: this.activeCompany.id,
        page: this.approvers.current_page,
      })

      this.approvers = {
        ...data,
        data: uniqBy([...this.approvers.data, ...data.data], 'id'),
      }

      this.isApproversLoading = false
    }, 300),

    fetchMoreFollowers(reached) {
      if (reached) {
        this.followers.current_page++
        this.fetchFollowers(this.followerSearchName)
      }
    },

    fetchMoreApprovers(reached) {
      if (reached) {
        this.approvers.current_page++
        this.fetchApprovers(this.approverSearchName)
      }
    },

    create() {
      this.reset()

      this.editing = false

      this.$modal.show('department-form')
    },

    edit(department) {
      this.editing = true

      this.form = {
        ...department,
        selectedApprovers: department.approvers,
        selectedFollowers: department.followers,
      }

      this.$modal.show('department-form')
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        const method = this.editing ? 'put' : 'post'
        const uri = this.editing ? `departments/${this.form.id}` : 'departments'

        const approvers = this.form.selectedApprovers.map(
          approver => approver.id
        )

        const followers = this.form.selectedFollowers.map(
          follower => follower.id
        )

        const { data: department } = await this.$http[method](uri, {
          ...this.form,
          visible_scopes: this.visibleScopes,
          company_id: this.activeCompany.id,
          approvers,
          followers,
        })

        if (this.shouldAddSelfScope) {
          const scopes =
            department.visible_scopes && department.visible_scopes.length
              ? department.visible_scopes
              : []

          await this.$http['put'](`departments/${department.id}`, {
            ...department,
            visible_scopes: [...scopes, `departments:${department.id}`],
            approvers,
          })
        }

        await this.fetchDepartments()
        await this.fetchUser()

        this.success('Department saved successfully.')

        this.$modal.hide('department-form')
        this.reset()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    async destroy(department) {
      const confirmed = await this.confirm(
        (await this.fetchDepartmentDeleteWarningMessage(department)) +
          'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      await this.$http.delete(`departments/${department.id}`, {
        data: { company_id: this.activeCompany.id },
      })

      await this.fetchDepartments()

      await this.fetchUser()

      this.success('Department deleted successfully.')
    },

    async fetchDepartmentDeleteWarningMessage(department) {
      const data = await this.fetchDepartmentDetails(department)

      if (data && data.employments_count) {
        return `The ${department.name} department has ${
          data.employments_count > 1 ? 'some employees' : 'an employee'
        } linked to it. `
      }

      return ''
    },

    async fetchDepartmentDetails(department) {
      try {
        const { data } = await this.$http.get(`departments/${department.id}`, {
          params: {
            ...this.$route.query,
          },
        })

        return data
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    reset() {
      this.$nextTick(() => {
        this.form = {
          id: null,
          name: '',
          visible_scopes: ['everyone'],
          selectedApprovers: [],
          selectedFollowers: [],
        }
        this.loading = false
        this.editing = false

        this.$validator.reset()
      })
    },
  },
}
</script>
