<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title">
        <h2 class="page-title">Allowances Report</h2>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table
        class="ld-table ld-table-striped"
        data-cy="table-allowance-report-summary"
      >
        <thead>
          <tr>
            <th>Allowance</th>
            <th>Days / Hours</th>
            <th>Allowance Year</th>
            <th>Annual Allowance</th>
            <th v-if="hasToilOfferedForAtLeastOneAllowance">
              Time off in Lieu
            </th>
            <th>Carry Over</th>
            <th>Expired Carry Over</th>
            <th>Total Allowance</th>
            <th>Accrued Allowance</th>
            <th>Booked</th>
            <th>Taken</th>
            <th>Remaining to Book</th>
            <th>Remaining to Take</th>
            <th>Carried to Next Year</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="groupAllowanceByAllowanceType.length">
            <AllowanceSummaryReportTableAllowanceTypeGroup
              v-for="(allowancesGroup, index) in groupAllowanceByAllowanceType"
              :key="index"
              :allowances="allowancesGroup"
              :has-toil-offered-for-at-least-one-allowance="
                hasToilOfferedForAtLeastOneAllowance
              "
            />
          </template>
          <tr v-if="!loading && !groupAllowanceByAllowanceType.length">
            <td class="tw-bg-white" colspan="14">
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>
      <loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { groupBy, map } from 'lodash-es'
import AllowanceSummaryReportTableAllowanceTypeGroup from '@/components/reports/allowances/AllowanceSummaryReportTableAllowanceTypeGroup'
import FormatNumbers from '@/mixins/FormatNumbers'

const NoData = () => import('@/components/NoData')

export default {
  name: 'AllowanceSummaryReportTable',

  components: {
    AllowanceSummaryReportTableAllowanceTypeGroup,
    NoData,
    Loading,
  },

  mixins: [FormatNumbers],

  props: {
    employmentAllowanceSummaries: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    groupAllowanceByAllowanceType() {
      const groups = groupBy(
        this.employmentAllowanceSummaries,
        'allowance_type.name'
      )

      return map(groups, allowances => {
        return allowances.map((allowance, index) => ({
          ...allowance,
          is_allowance_type_group_first: index === 0,
          allowance_type_group_count: allowances.length,
        }))
      })
    },

    hasToilOfferedForAtLeastOneAllowance() {
      return this.employmentAllowanceSummaries.some(
        allowance => allowance.has_time_off_in_lieu
      )
    },
  },
}
</script>
